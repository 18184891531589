import React, { useEffect, useState, type ReactNode } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/popover";
import Link from "@react/widget/Link";
import Icon from "@react/widget/Icon";

export type RegionsData = Array<[string, Array<{
  locale: string;
  name: string;
  href: string;
}>]>;

interface Props {
  siteName: string;
  data: RegionsData;
  enabled: Array<string>;
};

const Regions: React.FC<Props> = ({ siteName, data, enabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [storedChoice, setStoredChoice] = useState<{choice?: string; locale?: string}>({});
  useEffect(() => {
    document.body.addEventListener("scroll", () => isOpen && setIsOpen(false));
    setStoredChoice(JSON.parse(localStorage.getItem("countryModalChoice") || '{}'));
  }, []);

  return (
    <Popover
      classNames={{
        content: [
          "bg-white text-slate-900 border shadow-y-md py-5 transition-opacity items-stretch justify-normal",
          "transition-all duration-300",
          "sm:mt-3 sm:inset-x-0 sm:top-full sm:right-auto sm:bottom-auto sm:rounded-xl",
          "md:mt-4 lg:mt-5 min-w-[calc(100vw-32px)] md:min-w-max"
        ],
      }}
      // shouldBlockScroll={true}
      placement="bottom-start"
      shadow="md"
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      shouldCloseOnBlur={true}
      data-open={isOpen ? "" : undefined}
      style={{
        position: "fixed",
        maxHeight: "initial",
      }}
    >
      <PopoverTrigger>
        <div className="group/trigger">
          <Link className="flex text-sm">
            <Icon className="mr-2" name="globe"/>
            {siteName}
            <Icon className={`text-xs ml-1.5 transition-transform group-aria-expanded/trigger:rotate-180`} name="chevron-down"/>
          </Link>
        </div>
      </PopoverTrigger>
      <PopoverContent className="overflow-y-auto max-h-[calc(100vh-58px)]" aria-label="Regions List">
        <div className="text-sm flex-nowrap md:divide-x border-split whitespace-nowrap [&>*]:pt-3.5 md:[&>*]:pt-0 md:flex">
          {data.map(([_c, _rs], k) => (
            <div key={`region-col-${k}`} className="px-5 sm:px-7.5 md:px-9">
              <div className="font-semibold pb-3.5 border-b border-split">{_c}</div>
              <ul className="mt-2 space-y-1.5">
                {_rs.map(({locale, name, href}, i) => (
                  <li key={`region-col-${k}-${i}`}><Link className="py-1 block" href={href} data-locale={locale} onClick={(e) => {
                    if(enabled.includes(locale)) {
                      e.preventDefault();
                      storedChoice.locale = locale;
                      localStorage.setItem("countryModalChoice", JSON.stringify(storedChoice));
                      window.location.href = href;
                    }
                  }}>{name}</Link></li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default Regions;